<template>
    <section :class="[isScrolled ? 'bg-white shadow-lg dark:bg-gray-900' : 'bg-opacity-0 text-white border-b border-slate-50/25']" id="navbar" class="fixed top-0 w-full z-[51] h-[56px]">
        <div class="container mx-auto">
            <nav class="flex space-x-5">
                <button class="flex-none flex justify-start items-center"
                        @click="this.selectedBlock = null; this.$router.push('/')">
                    <img class="w-14 h-14 scale-75" src="~@/assets/main.png"
                         alt="">
                    <p class="text-xs pl-1 uppercase text-start dark:text-lime-400 2xl:block xl:block hidden">
                        {{ $t('navbar.logo-text-part-1') }}<br> {{ $t('navbar.logo-text-part-2') }} </p>
                </button>
                <ul class="flex 2xl:justify-between xl:justify-between lg:justify-between justify-end items-center w-full dark:text-lime-500">
                    <li @click="showBlock(1)" :class="buttonClasses(1)"
                        class="2xl:block xl:block lg:block hidden select-none py-2 px-4 hover:bg-blue-100/50 dark:text-lime-500 hover:bg-white hover:text-blue-500 cursor-pointer transition-colors ease-in rounded-md">
                        {{ $t('navbar.about-us') }}
                    </li>
                    <li @click="showBlock(2)" :class="buttonClasses(2)"
                        class="2xl:block xl:block lg:block hidden select-none py-2 px-4 hover:bg-blue-100/50 dark:text-lime-500 hover:bg-white hover:text-blue-500 cursor-pointer transition-colors ease-in rounded-md">
                        {{ $t('navbar.our-services') }}
                    </li>
                    <li @click="showBlock(5)" :class="buttonClasses(5)"
                        class="2xl:block xl:block lg:block hidden select-none py-2 px-4 hover:bg-blue-100/50 dark:text-lime-500 hover:bg-white hover:text-blue-500 cursor-pointer transition-colors ease-in rounded-md">
                        {{ $t('navbar.interactive') }}
                    </li>
                    <li @click="showBlock(3)" :class="buttonClasses(3)"
                        class="2xl:block xl:block lg:block hidden select-none py-2 px-4 hover:bg-blue-100/50 dark:text-lime-500 hover:bg-white hover:text-blue-500 cursor-pointer transition-colors ease-in rounded-md">
                        {{ $t('navbar.cooperate') }}
                    </li>
                    <li @click="showBlock(4)" :class="buttonClasses(4)"
                        class="2xl:block xl:block lg:block hidden select-none py-2 px-4 hover:bg-blue-100/50 dark:text-lime-500 hover:bg-white hover:text-blue-500 cursor-pointer transition-colors ease-in rounded-md">
                        {{ $t('navbar.press-center') }}
                    </li>
                    <li @click="showBlock(8)" :class="buttonClasses(8)"
                        class="2xl:block xl:block lg:block hidden select-none py-2 px-4 hover:bg-blue-100/50 dark:text-lime-500 hover:bg-white hover:text-blue-500 cursor-pointer transition-colors ease-in rounded-md">
                        {{ $t('navbar.dot') }}
                    </li>
                    <li class="flex items-center justify-between">
                        <button @click="this.selectedBlock = null; this.$router.push('searchPage')"
                                class="transition-all ease-in flex justify-between items-center w-[73px] rounded-md h-[36px] hover:text-blue-500 dark:text-lime-400 hover:bg-blue-50 dark:hover:bg-transparent">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                 stroke="currentColor" class="w-4 h-4 mx-auto">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </button>

                        <div
                                class="locale-changer flex justify-between items-center w-[73px] font-medium border-gray-100 md:w-auto hover:bg-blue-200 md:hover:bg-transparent md:border-0 md:p-0 dark:text-lime-400 md:dark:hover:bg-transparent dark:border-gray-700">
                            <select
                                    class="transition-all ease-in cursor-pointer hover:bg-blue-50 rounded-md bg-transparent border-0 hover:text-blue-500 bg-sky-500 dark:text-lime-400 dark:hover:bg-gray-900 dark:border-gray-900"
                                    @change="updateLanguage()" v-model="$i18n.locale">
                                <option class="text-black hover:text-blue-500 dark:text-lime-400"
                                        v-for="(locale, i) in locales"
                                        :key="`locale-${i}`" :value="locale">
                                    {{   locale.toUpperCase() }}
                                </option>
                            </select>
                        </div>

                        <button @click="showBlock(6)"
                                class="transition-all ease-in w-[73px] h-[36px] rounded-md hover:text-blue-500 dark:text-lime-400 hover:bg-blue-50 dark:hover:bg-transparent 2xl:block xl:block lg:block hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                 stroke="currentColor" class="h-5 mx-auto">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"/>
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                            </svg>
                        </button>

                        <button @click="showBlock(7)"
                                class="2xl:hidden xl:hidden lg:hidden transition-all ease-in w-[73px] h-[36px] rounded-md hover:text-blue-500 dark:text-lime-400 hover:bg-blue-50 dark:hover:bg-transparent">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                 stroke="currentColor" class="mx-auto h-5">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
                            </svg>

                        </button>

                    </li>
                </ul>
            </nav>
        </div>
    </section>

    <div v-if="selectedBlock === 1" :class="blockClasses(1)"
         class="z-[51] h-[235px] flex flex-col justify-center items-center border-gray-200 shadow-lg border-y dark:bg-gray-800 dark:border-gray-600 bg-gray-100 py-3 mt-navbar fixed top-0 w-full">
        <div class="flex justify-center mt-2">
            <div class="flex-col">
                <p class="text-blue-500 dark:text-lime-400">
                    {{ $t('navbar.logo-text-part-1') }} {{ $t('navbar.logo-text-part-2') }}</p>
                <hr class="h-px bg-blue-500 border-0 dark:bg-gray-700 animate-pulse">
            </div>
        </div>
        <div
                class="grid py-5 px-4 container mx-auto w-full text-gray-900 dark:text-white grid-cols-3">
            <ul>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/profile')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.profile') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/ceoMessage')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light  ">{{ $t('navbar.ceo-message') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/history')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.history') }}
                        </div>
                    </div>
                </li>
            </ul>
            <ul>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/mission')"
                    class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.mission') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/function')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.function') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/headliners')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.headliners') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/orgStructure')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.orgStructure') }}
                        </div>
                    </div>
                </li>
            </ul>
            <ul>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/structure')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.structure') }}
                        </div>
                    </div>
                </li>
                
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/membership')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.membership') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/vacancy')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.vacancy') }}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <div v-if="selectedBlock === 2" :class="blockClasses(2)"
         class="z-[51] h-[235px] flex flex-col justify-center items-center border-gray-200 shadow-lg border-y dark:bg-gray-800 dark:border-gray-600 bg-gray-100 py-3 mt-navbar fixed top-0 w-full">
        <div
                class="grid py-5 px-4 container mx-auto w-full text-gray-900 dark:text-white grid-cols-4">
            <ul>
                <li>
                    <p class="text-blue-500 dark:text-lime-400">
                        {{ $t('navbar.list-services') }}</p>
                    <hr class="h-px w-1/2 bg-blue-500 animate-pulse border-0 dark:bg-gray-700 mb-2">
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/depoService')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.depository-services') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/itService')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light  ">{{
                            $t('navbar.information-services')
                            }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/consultService')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.advisory-services') }}
                        </div>
                    </div>
                </li>
            </ul>
            <ul>
                <li>
                    <p class="text-blue-500 dark:text-lime-400">
                        {{ $t('navbar.new-services') }}</p>
                    <hr class="h-px w-1/2 bg-blue-500 animate-pulse border-0 dark:bg-gray-700 mb-2">
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/developing')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.evote-service') }}
                        </div>
                    </div>
                </li>
            </ul>
            <ul>
                <li>
                    <p class="text-blue-500 dark:text-lime-400">
                        {{ $t('navbar.tarifs') }}</p>
                    <hr class="h-px w-1/2 bg-blue-500 animate-pulse border-0 dark:bg-gray-700 mb-2">
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/activeTarif')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.active-tariffs') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/tarif')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.tariffs-archive') }}
                        </div>
                    </div>
                </li>
            </ul>
            <ul>
                <li>
                    <p class="text-blue-500 dark:text-lime-400">
                        {{ $t('home-page.contacts') }}</p>
                    <hr class="h-px w-1/2 bg-blue-500 animate-pulse border-0 dark:bg-gray-700 mb-2">
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/review')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.our-reviews') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/contacts')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('home-page.contact-us') }}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <div v-if="selectedBlock === 3" :class="blockClasses(3)"
         class="z-[51] h-[235px] flex flex-col justify-center items-center border-gray-200 shadow-lg border-y dark:bg-gray-800 dark:border-gray-600 bg-gray-100 py-3 mt-navbar fixed top-0 w-full">
        <div
                class="grid py-5 px-4 container mx-auto w-full text-gray-900 dark:text-white grid-cols-4">
            <ul aria-labelledby="mega-menu-full-dropdown-button-co">
                <li>
                    <p class="text-blue-500 dark:text-lime-400">
                        {{ $t('navbar.disclosure-information') }}</p>
                    <hr class="h-px w-1/2 bg-blue-500 animate-pulse border-0 dark:bg-gray-700 mb-2">
                </li>
                <li>

                    <div @click="this.selectedBlock = null; this.$router.push('/affiliates')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.list-affiliated') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/essentialFacts')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.essential-facts') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/businessPlan')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.bussines-plan') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/businessExecution')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.estimate-execution') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/kpi')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.performance-indicators') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/strategies')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.development-strategy') }}
                        </div>
                    </div>
                </li>
            </ul>
            <ul>
                <li>
                    <p class="text-blue-500 dark:text-lime-400">
                        {{ $t('navbar.financial-statements') }}</p>
                    <hr class="h-px w-1/2 bg-blue-500 animate-pulse border-0 dark:bg-gray-700 mb-2">
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/financialReport')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.annual-reports') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/msfo')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.ifrs-reporting') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/purchases')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.information-purchases') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/externalAudit')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.external-audit') }}
                        </div>
                    </div>
                </li>
            </ul>
            <ul class="hidden md:block">
                <li>
                    <p class="text-blue-500 dark:text-lime-400">
                        {{ $t('navbar.management-control') }}</p>
                    <hr class="h-px w-1/2 bg-blue-500 animate-pulse border-0 dark:bg-gray-700 mb-2">
                </li>
                <li>

                    <div @click="this.selectedBlock = null; this.$router.push('/supervisoryBoard')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.supervisory-board') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/executiveBody')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.executive-body') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div disabled
                         class="transition-all ease-in dark:text-gray-500 cursor-no-drop block p-1 rounded-lg text-gray-300 hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-red-400 dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.internal-audit') }}
                        </div>
                    </div>
                </li>
            </ul>
            <ul class="hidden md:block">
                <li>
                    <p class="text-blue-500 dark:text-lime-400">
                        {{ $t('navbar.list-documents') }}</p>
                    <hr class="h-px w-1/2 bg-blue-500 animate-pulse border-0 dark:bg-gray-700 mb-2">
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/internalDocuments')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.internal-documents') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/corporateCharter')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.corporate-charter') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/securitiesIssue')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.securities-issue') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/regulatoryDocuments')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.regulatory-documents') }}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <div v-if="selectedBlock === 4" :class="blockClasses(4)"
         class="z-[51] h-[235px] flex flex-col justify-center items-center border-gray-200 shadow-lg border-y dark:bg-gray-800 dark:border-gray-600 bg-gray-100 py-3 mt-navbar fixed top-0 w-full">
        <div
                class="grid py-5 px-4 container mx-auto w-full text-gray-900 dark:text-white grid-cols-4">
            <ul aria-labelledby="mega-menu-full-dropdown-button-pc">
                <li>
                    <p class="text-blue-500 dark:text-lime-400">
                        {{ $t('navbar.matirials') }}</p>
                    <hr class="h-px w-1/2 bg-blue-500 animate-pulse border-0 dark:bg-gray-700 mb-2">
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/news')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.news') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/pressRelease')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.press-releases') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/publications')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.publications') }}
                        </div>
                    </div>
                </li>
            </ul>
            <ul class="hidden md:block">
                <li>
                    <p class="text-blue-500 dark:text-lime-400">
                        {{ $t('navbar.media-matirials') }}</p>
                    <hr class="h-px w-1/2 bg-blue-500 animate-pulse border-0 dark:bg-gray-700 mb-2">
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/media')"
                         class="transition-all ease-in block p-1 font-light rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700 cursor-pointer  ">

                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.photo') }}
                        </div>
                    </div>
                </li>
                <li>
                    <!-- <div disabled
                         class="transition-all ease-in dark:text-gray-500 cursor-not-allowed block p-1 text-gray-300 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700"> -->

                    <div disabled
                    @click="this.selectedBlock = null; this.$router.push('/newVideo')" 
                    class="transition-all ease-in block p-1 fon  t-light rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700 cursor-pointer ">
                             

                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.video') }}
                        </div>
                    </div>
                </li>
                <li>
                    <!-- <div disabled
                         class="transition-all ease-in dark:text-gray-500 cursor-not-allowed block p-1 text-gray-300 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700"> -->

                    <div @click="this.selectedBlock = null; this.$router.push('/audios')"
                         class="transition-all ease-in block p-1 font-light rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700 cursor-pointer  ">

                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.audio') }}
                        </div>
                    </div>
                </li>
            </ul>
            <ul class="hidden md:block">
                <li>
                    <p class="text-blue-500 dark:text-lime-400">
                        {{ $t('navbar.additional-information') }}</p>
                    <hr class="h-px w-1/2 bg-blue-500 animate-pulse border-0 dark:bg-gray-700 mb-2">
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/statisticalInformation')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.statistical-information') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/results')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.results') }}
                        </div>
                    </div>
                </li>
            </ul>
            <ul class="hidden md:block">
                <li>
                    <div class="bg-blue-500 dark:bg-lime-500 w-[300px] h-[150px] rounded-md shadow-lg">
                        <div
                             class="p-5 h-full space-y-5 text-left bg-center bg-no-repeat bg-cover rounded-lg bg-blend-multiply hover:bg-blend-soft-light dark:hover:bg-blend-darken">
                            <p class="font-extrabold leading-tight tracking-tight text-white">{{
                                $t('navbar.depos')
                                }}</p>
                            <a href="https://depo.uzcsd.uz" target="_blank"
                                    class="transition-all ease-in inline-flex items-center px-2.5 py-1.5 text-xs font-medium text-center text-white border border-white rounded-lg hover:bg-white hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-700">
                                {{ $t('home-page.website') }}
                                <svg class="w-4 h-4 ml-1 -mr-1" fill="currentColor" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                          clip-rule="evenodd"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <div v-if="selectedBlock === 5" :class="blockClasses(5)"
         class="z-[51] h-[235px] flex flex-col justify-center items-center border-gray-200 shadow-lg border-y dark:bg-gray-800 dark:border-gray-600 bg-gray-100 py-3 mt-navbar fixed top-0 w-full">
        <div
                class="grid py-5 px-4 container mx-auto w-full text-gray-900 dark:text-white grid-cols-5">
            <ul aria-labelledby="mega-menu-full-dropdown-button-in">
                <li>
                    <p class="text-blue-500 dark:text-lime-400">
                        {{ $t('navbar.open-inf') }}</p>
                    <hr class="h-px w-1/2 bg-blue-500 animate-pulse border-0 dark:bg-gray-700 mb-2">
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/isinAndcfi')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">

                        <div class="font-light text-start 2xl:whitespace-nowrap">
                            {{ $t('home-page.list-of-assigned') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/issuersList')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">

                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('home-page.list-of-joint-stock') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/issuersList')"
                         class="transition-all ease-in dark:text-gray-500 cursor-not-allowed block p-1 text-gray-300 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700">
                        <div class="font-light text-start">
                            {{ $t('home-page.central-bank') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div disabled
                         class="transition-all ease-in dark:text-gray-500 cursor-not-allowed block p-1 text-gray-300 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700">

                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('home-page.search-for-issuers') }}
                        </div>
                    </div>
                </li>
            </ul>
            <ul>
                <li>
                    <p class="text-blue-500 dark:text-lime-400">
                        {{ $t('navbar.template') }}</p>
                    <hr class="h-px w-1/2 bg-blue-500 animate-pulse border-0 dark:bg-gray-700 mb-2">
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/templates')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.template-blanks') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/glossary')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.dictionary-terms') }}
                        </div>
                    </div>
                </li>
            </ul>
            <ul>
                <li>
                    <p class="text-blue-500 dark:text-lime-400">
                        {{ $t('navbar.swift-message') }}</p>
                    <hr class="h-px w-1/2 bg-blue-500 animate-pulse border-0 dark:bg-gray-700 mb-2">
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/iso15022')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.iso-15022') }}
                        </div>
                    </div>
                </li>
                <li>
                    <!-- <div disabled
                         class="transition-all ease-in dark:text-gray-500 cursor-not-allowed block p-1 text-gray-300 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700"> -->

                    <div @click="this.selectedBlock = null; this.$router.push('/iso20022')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.iso-20022') }}
                        </div>
                    </div>
                </li>
            </ul>
            <ul class="hidden md:block">
                <li>
                    <p class="text-blue-500 dark:text-lime-400">
                        {{ $t('navbar.additional-information') }}</p>
                    <hr class="h-px w-1/2 bg-blue-500 animate-pulse border-0 dark:bg-gray-700 mb-2">
                </li>
                <li>
                    <div disabled
                         class="transition-all ease-in dark:text-gray-500 cursor-not-allowed block p-1 text-gray-300 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700">

                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.questionnaire') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div disabled
                         class="transition-all ease-in dark:text-gray-500 cursor-not-allowed block p-1 text-gray-300 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700">

                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.online-appeal') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div disabled
                         class="transition-all ease-in dark:text-gray-500 cursor-no-drop block p-1 text-gray-300 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-red-400 dark:hover:bg-gray-700">

                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.frequenly-questions') }}
                        </div>
                    </div>
                </li>
            </ul>
            <ul class="hidden md:block">
                <li>
                    <p class="text-blue-500 dark:text-lime-400">
                        {{ $t('navbar.list-system') }}</p>
                    <hr class="h-px w-1/2 bg-blue-500 animate-pulse border-0 dark:bg-gray-700 mb-2">
                </li>
                <li>
                    <a target="_blank" href="https://pc.uzcsd.uz"
                         class="transition-all ease-in dark:text-lime-500 cursor-pointer block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700">

                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.my-cabinet') }}
                        </div>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://evote.uz"
                         class="transition-all ease-in dark:text-lime-500 cursor-pointer block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700">

                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.e-vote') }}
                        </div>
                    </a>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/offertas')"
                        class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.offerta') }}
                        </div>
                    </div>
                </li>
                <!-- <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/newOffertas')"
                        class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.offerta') }}
                        </div>
                    </div>
                </li> -->
            </ul>
        </div>
    </div>

    <div v-if="selectedBlock === 6" :class="blockClasses(6)"
         class="border-gray-200 shadow-lg border-y dark:bg-gray-800 dark:border-gray-600 bg-gray-100 py-3 absolute mt-navbar">

        <div
                class="fixed top-0 z-[51] w-full extra h-navbar shadow-md bg-white dark:border-gray-600 dark:bg-gray-900 2xl:block xl:block lg:block hidden">
            <div class="container mx-auto flex justify-around items-center h-navbar dark:text-white">
                <div class="flex flex-col dark:text-lime-400">
                    <div class="text-sm">{{ $t('navbar.font-size') }}</div>
                    <div class="flex justify-between">
                        <button @click="changeFont('text-xs')"
                                class="text-xs hover:bg-gray-500 rounded-sm h-6 w-6 leading-none">A
                        </button>
                        <button @click="changeFont('text-sm')"
                                class="text-sm hover:bg-gray-500 rounded-sm h-6 w-6 leading-none">A
                        </button>
                        <button @click="changeFont('text-base')"
                                class="text-base hover:bg-gray-500 rounded-sm h-6 w-6 leading-none">
                            A
                        </button>
                        <button @click="changeFont('text-lg')"
                                class="text-lg hover:bg-gray-500 rounded-sm h-6 w-6 leading-none">A
                        </button>
                        <button @click="changeFont('text-xl')"
                                class="text-xl hover:bg-gray-500 rounded-sm h-6 w-6 leading-none">A
                        </button>
                    </div>
                </div>

                <div class="flex flex-col dark:text-lime-400">
                    <div class="text-sm">{{ $t('navbar.theme') }}</div>
                    <div class="flex justify-center space-x-10">
                        <button @click="changeTheme('dark')"
                                class="matrix rounded-3xl bg-gradient-to-r from-black to-lime-500 w-6 h-6">
                        </button>
                        <button @click="remover()"
                                class="matrix rounded-3xl bg-gradient-to-r from-slate-300 to-slate-100 w-6 h-6">
                        </button>
                    </div>
                </div>

                <div class="dark:text-lime-400">
                    <button class="text-sm" @click="remover()">
                      {{ $t('navbar.clear-theme') }}
                    </button>
                </div>
                <div>
                    <button id="closeExtra" @click="closeAll()">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-6 h-6 mt-2 dark:text-lime-400">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>

    </div>

    <div v-if="selectedBlock === 7" :class="blockClasses(7)"
         class="whitespace-nowrap p-5 z-[51] h-screen flex flex-col justify-start items-start shadow-lg dark:bg-gray-800 dark:border-gray-600 bg-blue-500 fixed top-0 w-full">

        <div class="flex justify-between w-full mb-4 text-white text-2xl border-b p-1">
            <p class="text-center">{{ $t('others.menu') }}</p>
            <button @click="closeAll()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
                </svg>
            </button>
        </div>

        <button @click="showDropdown(1)"
                class="text-xl py-2 text-bold text-white flex items-center transition-all ease-in dark:text-lime-400">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"/>
            </svg>
            {{ $t('navbar.about-us') }}
        </button>
        <div :class="dropdownClasses(1)">
            <ul>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/profile')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.profile') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/ceoMessage')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light  ">{{ $t('navbar.ceo-message') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/history')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.history') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/mission')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.mission') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/function')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.function') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/headliners')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.headliners') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/orgStructure')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.orgStructure') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/structure')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.structure') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/membership')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.membership') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/vacancy')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.vacancy') }}
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <button @click="showDropdown(2)"
                class="text-xl py-2 text-bold text-white flex items-center transition-all ease-in dark:text-lime-400">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"/>
            </svg>
            {{ $t('navbar.list-services') }}
        </button>
        <div :class="dropdownClasses(2)">
            <ul>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/depoService')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.depository-services') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/itService')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light  ">{{
                            $t('navbar.information-services')
                            }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/consultService')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.advisory-services') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/developing')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.new-services') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/activeTarif')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.active-tariffs') }}
                        </div>
                    </div>
                </li>
              <li>
                <div @click="this.selectedDropdown = null; this.$router.push('/tarif')"
                     class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                  <div class="font-light">
                    {{ $t('navbar.tariffs-archive') }}
                  </div>
                </div>
              </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/review')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.our-reviews') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/contacts')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('home-page.contact-us') }}
                        </div>
                    </div>
                </li>
            </ul>
        </div>

      <button @click="showDropdown(5)"
              class="text-xl py-2 text-bold text-white flex items-center transition-all ease-in dark:text-lime-400">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
             stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"/>
        </svg>
        {{ $t('navbar.interactive') }}
      </button>
      <div :class="dropdownClasses(5)">
        <ul>
          <li>

            <div @click="this.selectedDropdown = null; this.$router.push('/isinAndcfi')"
                 class="transition-all ease-in dark:text-lime-500 cursor-pointer block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-red-400 dark:hover:bg-gray-700">

              <div class="font-light text-start 2xl:w-64">
                {{ $t('home-page.list-of-assigned') }}
              </div>
            </div>
          </li>
          <li>
            <div @click="this.selectedDropdown = null; this.$router.push('/issuersList')"
                 class="transition-all ease-in dark:text-lime-500 cursor-pointer block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700">

              <div class="font-light text-start">
                {{ $t('home-page.central-bank') }}
              </div>
            </div>
          </li>
          <li>
            <div disabled
                 class="transition-all ease-in dark:text-gray-500 cursor-not-allowed block p-1 text-gray-300 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700">

              <div class="font-light text-start 2xl:w-64">
                {{ $t('home-page.search-for-issuers') }}
              </div>
            </div>
          </li>
          <li>
            <div @click="this.selectedDropdown = null; this.$router.push('/templates')"
                 class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
              <div class="font-light text-start 2xl:w-64">
                {{ $t('navbar.template-blanks') }}
              </div>
            </div>
          </li>
          <li>
            <div @click="this.selectedDropdown = null; this.$router.push('/glossary')"
                 class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
              <div class="font-light text-start 2xl:w-64">
                {{ $t('navbar.dictionary-terms') }}
              </div>
            </div>
          </li>
          <li>
            <div @click="this.selectedDropdown = null; this.$router.push('/iso15022')"
                 class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
              <div class="font-light text-start 2xl:w-64">
                {{ $t('navbar.iso-15022') }}
              </div>
            </div>
          </li>
          <li>
            <!-- <div disabled
                 class="transition-all ease-in dark:text-gray-500 cursor-not-allowed block p-1 text-gray-300 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700"> -->

            <div @click="this.selectedDropdown = null; this.$router.push('/iso20022')"
                 class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
              <div class="font-light text-start 2xl:w-64">
                {{ $t('navbar.iso-20022') }}
              </div>
            </div>
          </li>
          <li>
            <div disabled
                 class="transition-all ease-in dark:text-gray-500 cursor-not-allowed block p-1 text-gray-300 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700">

              <div class="font-light text-start 2xl:w-64">
                {{ $t('navbar.questionnaire') }}
              </div>
            </div>
          </li>
          <li>
            <div disabled
                 class="transition-all ease-in dark:text-gray-500 cursor-not-allowed block p-1 text-gray-300 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700">

              <div class="font-light text-start 2xl:w-64">
                {{ $t('navbar.online-appeal') }}
              </div>
            </div>
          </li>
          <li>
            <div disabled
                 class="transition-all ease-in dark:text-gray-500 cursor-no-drop block p-1 text-gray-300 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-red-400 dark:hover:bg-gray-700">

              <div class="font-light text-start 2xl:w-64">
                {{ $t('navbar.frequenly-questions') }}
              </div>
            </div>
          </li>
          <li>
            <div onclick="location.href='https://pc.uzcsd.uz';"
                 class="transition-all ease-in dark:text-lime-500 cursor-pointer block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700">

              <div class="font-light text-start 2xl:w-64">
                {{ $t('navbar.my-cabinet') }}
              </div>
            </div>
          </li>
          <li>
            <div onclick="location.href='https://evote.uz'"
                 class="transition-all ease-in dark:text-lime-500 cursor-pointer block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700">

              <div class="font-light text-start 2xl:w-64">
                {{ $t('navbar.e-vote') }}
              </div>
            </div>
          </li>
          <li>
            <div @click="this.selectedDropdown = null; this.$router.push('/offertas')"
                    class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                <div class="font-light text-start 2xl:w-64">
                    {{ $t('navbar.offerta') }}
                </div>
            </div>
          </li>
        </ul>
      </div>

        <button @click="showDropdown(3)"
                class="text-xl py-2 text-bold text-white flex items-center transition-all ease-in dark:text-lime-400">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"/>
            </svg>
            {{ $t('navbar.cooperate') }}
        </button>
        <div :class="dropdownClasses(3)">
            <ul>
                <li>

                    <div @click="this.selectedBlock = null; this.$router.push('/affiliates')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.list-affiliated') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/essentialFacts')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.essential-facts') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/businessPlan')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.bussines-plan') }}
                        </div>
                    </div>
                </li>
              <li>
                <div @click="this.selectedBlock = null; this.$router.push('/businessExecution')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.estimate-execution') }}
                        </div>
                    </div>
              </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/kpi')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.performance-indicators') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/strategies')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.development-strategy') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/financialReport')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.annual-reports') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/msfo')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.ifrs-reporting') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/purchases')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.information-purchases') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/externalAudit')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.external-audit') }}
                        </div>
                    </div>
                </li>
                <li>

                    <div @click="this.selectedBlock = null; this.$router.push('/supervisoryBoard')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.supervisory-board') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/executiveBody')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.executive-body') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div disabled
                         class="transition-all ease-in dark:text-gray-500 cursor-no-drop block p-1 rounded-lg text-gray-300 hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-red-400 dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.internal-audit') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/internalDocuments')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.internal-documents') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/corporateCharter')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.corporate-charter') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/securitiesIssue')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.securities-issue') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/regulatoryDocuments')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.regulatory-documents') }}
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <button @click="showDropdown(4)"
                class="text-xl py-2 text-bold text-white flex items-center transition-all ease-in dark:text-lime-400">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"/>
            </svg>
            {{ $t('navbar.press-center') }}
        </button>
        <div :class="dropdownClasses(4)">
            <ul>
                <li>

                    <div @click="this.selectedDropdown = null; this.$router.push('/news')"
                         class="transition-all ease-in dark:text-lime-500 cursor-no-drop block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-red-400 dark:hover:bg-gray-700">

                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.news') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/pressRelease')"
                         class="transition-all ease-in dark:text-lime-500 cursor-no-drop block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-red-400 dark:hover:bg-gray-700">

                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.press-releases') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/publications')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.publications') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/media')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.photo') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/newVideo')"
                        class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.video') }}
                        </div>
                    </div>
                </li>
                <li>
                    <!-- <div disabled
                         class="transition-all ease-in dark:text-gray-500 cursor-not-allowed block p-1 text-gray-300 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 dark:hover:bg-gray-700"> -->

                    <div @click="this.selectedDropdown = null; this.$router.push('/audios')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.audio') }}
                        </div>
                    </div>
                </li>
                
              <li>
                <div @click="this.selectedDropdown = null; this.$router.push('/statisticalInformation')"
                     class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                  <div class="font-light text-start 2xl:w-64">
                    {{ $t('navbar.statistical-information') }}
                  </div>
                </div>
              </li>
              <li>
                <div @click="this.selectedDropdown = null; this.$router.push('/results')"
                     class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                  <div class="font-light text-start 2xl:w-64">
                    {{ $t('navbar.results') }}
                  </div>
                </div>
              </li>
            </ul>
        </div>
        <button @click="showDropdown(8)"
                class="text-xl py-2 text-bold text-white flex items-center transition-all ease-in dark:text-lime-400">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"/>
            </svg>
            {{ $t('navbar.fight-against-corruption') }}
        </button>
        <div :class="dropdownClasses(8)">
            <ul>
                <li>

                    <div @click="this.selectedDropdown = null; this.$router.push('/directorGeneralsAppeal')"
                         class="transition-all ease-in dark:text-lime-500 cursor-no-drop block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-red-400 dark:hover:bg-gray-700">

                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.director-generals-appeal') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/developing')"
                         class="transition-all ease-in dark:text-lime-500 cursor-no-drop block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-red-400 dark:hover:bg-gray-700">

                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.industry-legal-document') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/departamentRegulatoryDocuments')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.departament-internal-regulatory-documents') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/practicalactivities')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.practical-activities') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedDropdown = null; this.$router.push('/communicationChannels')"
                         class="transition-all ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light text-start 2xl:w-64">
                            {{ $t('navbar.comunication-channels') }}
                        </div>
                    </div>
                </li>
            </ul>
        </div>

    </div>

    <div v-if="selectedBlock === 8" :class="blockClasses(8)"
    class="z-[51] h-[235px] flex flex-col justify-right items-right border-gray-200 shadow-lg border-y dark:bg-gray-800 dark:border-gray-600 bg-gray-100 py-3 mt-navbar fixed top-0 w-full">
        <div class="flex justify-center mt-5">
            <div class="flex-col">
                <p class="text-blue-500 dark:text-lime-400">
                    {{ $t('navbar.fight-against-corruption') }}</p>
                <hr class="h-px bg-blue-500 border-0 dark:bg-gray-700 animate-pulse">
            </div>
        </div>
        <div
                class="flex justify-center py-5 px-4 container mx-auto w-full text-gray-900 dark:text-white grid-cols-1 gap-11">
            <ul>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/directorGeneralsAppeal')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.director-generals-appeal') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/developing')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.industry-legal-document') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/departamentRegulatoryDocuments')"
                    class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                           {{ $t('navbar.departament-internal-regulatory-documents') }}
                        </div>
                    </div>
                </li>
            </ul>
            <ul>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/practicalactivities')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.practical-activities') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div @click="this.selectedBlock = null; this.$router.push('/communicationChannels')"
                         class="transition-colors ease-in block p-1 rounded-lg hover:bg-blue-200 text-sm hover:text-blue-500 dark:hover:text-lime-400 cursor-pointer dark:hover:bg-gray-700">
                        <div class="font-light">
                            {{ $t('navbar.comunication-channels') }}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    
</template>

<script>
export default {
    data() {
        return {
            locales: ["ru", "en", "uz"],
            selectedBlock: null,
            selectedDropdown: null,
            sizes: ['text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl'],
            themes: 'dark',
            isScrolled: false
        }
    },
    methods: {
        updateLanguage() {
            localStorage.setItem("locale", this.$i18n.locale);
        },
        showBlock(blockNumber) {
            if (this.selectedBlock === blockNumber) {
                this.selectedBlock = null;
            } else {
                this.selectedBlock = blockNumber;
                this.isScrolled = true;
            }
        },
        closeAll() {
            this.selectedBlock = null;
            this.selectedDropdown = null;
        },
        showDropdown(dropdownNumber) {
            if (this.selectedDropdown === dropdownNumber) {
                this.selectedDropdown = null;
            } else {
                this.selectedDropdown = dropdownNumber;
            }
        },
        buttonClasses(blockNumber) {
            return {
                '2xl:block xl:block lg:block hidden select-none py-2 px-4 bg-blue-100/50 text-blue-500 cursor-pointer transition-colors ease-in rounded-md': this.selectedBlock === blockNumber
            };
        },
        blockClasses(blockNumber) {
            return {
                'hidden': this.selectedBlock !== blockNumber,
                'block': this.selectedBlock === blockNumber,
                'transition duration-500 ease-linear': true,
            };
        },
        dropdownClasses(dropdownNumber) {
            return {
                'hidden': this.selectedDropdown !== dropdownNumber,
                'block': this.selectedDropdown === dropdownNumber,
                'transition duration-500 ease-linear pl-4 border border-white bg-blue-500/25 p-2 text-white rounded-md shadow-lg w-full ': true,
            };
        },
        remover() {
            this.clearFont()
            this.clearTheme()
            localStorage.clear()
        },
        clearFont() {
            this.sizes.forEach((item) => {
                let element = document.body;
                element.classList.remove(item)
            })
        },
        clearTheme() {
            let element = document.body;
            element.classList.remove('dark')
        },
        changeFont(name) {
            this.clearFont();
            let element = document.body;
            element.classList.add(name);
            this.sizes.forEach((item) => {
                if (name == item) {
                    localStorage.setItem('font', name)
                }
            })
        },
        changeTheme(theme) {
            this.clearTheme();
            let element = document.body;
            if (element.classList.contains(theme)) {
                element.classList.remove(theme);
            } else {
                element.classList.add(theme);
            }
            localStorage.setItem('theme', theme);
        },
        handleScroll() {
            const scrollPosition = window.scrollY;
            this.isScrolled = scrollPosition > 0;
        },
    },
    mounted() {
        if (localStorage.getItem("locale")) {
            this.$i18n.locale = localStorage.getItem("locale");
        } else {
            localStorage.setItem("locale", this.$i18n.locale);
        }
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>